<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    &gt; <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                    &gt; <router-link :to="{ name: 'account-search-form', params: { accountId: this.$route.params.accountId } }">Forms</router-link>
                    <template v-if="form">&gt; <router-link :to="{ name: 'account-edit-form', params: { accountId: this.$route.params.accountId, formId: this.$route.params.formId } }">{{ form.label }}</router-link></template>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ form.label }}</h1>
                <p class="text-caption text-center">View form data</p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="form">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption grey--text text-end my-0">{{ typeDisplay }} {{ form.id }}</p>
                    <v-card>
                        <v-app-bar color="purple darken-2" dense dark elevation="2">
                            <v-app-bar-title>
                                Form data
                            </v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="loadFormData" :color="refreshButtonColor">
                                <font-awesome-icon :icon="['far', 'sync-alt']" />
                            </v-btn>

                            <v-menu bottom left offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon color="white" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'caret-down']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <!-- <v-list-item-group> -->
                                    <v-list-item>
                                        <v-checkbox v-model="isAutorefreshEnabled" label="Auto-refresh" dense></v-checkbox>
                                        <!-- <v-list-item-content> -->
                                            <!-- <v-list-item-title class="red--text">Delete</v-list-item-title> -->
                                        <!-- </v-list-item-content> -->
                                    </v-list-item>
                                    <v-list-item>
                                        <v-checkbox v-model="showIncompleteEntries" label="Show incomplete entries" dense/>
                                    </v-list-item>
                                    <!-- </v-list-item-group> -->
                                </v-list>
                            </v-menu>

                        </v-app-bar>
                        <v-card-text>
                            <!-- <p class="text-caption text-end">{{ typeDisplay }} {{ form.id }}</p> -->
                            <!-- <p class="text-overline mb-0 mt-8">Label</p>
                            <p class="mb-0 pb-0">
                                {{ form.label }}
                            </p> -->
                            <h1 class="text-center">{{ form.label }}</h1>

                            <p class="text-center text-caption mt-2 mb-0">
                                <a @click="editTimeFilterDialog = true">
                                    {{ dateFilterDisplay }}
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width class="purple--text text--lighten-1 no-print" size="1x"/>
                                </a>
                            </p>
                            <p class="text-center text-caption mt-0 mb-0">
                                {{ countTotalEntries }} entries
                            </p>
                            <p class="text-center text-caption mt-0 mb-0">
                                {{ countCompleteEntries }} completed ({{ completePercentageRounded }}%)
                            </p>
                            <!-- <v-simple-table dense class="mt-2">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <td>Measure</td>
                                            <td>Count</td>
                                            <td>Percentage</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total entries</td>
                                            <td>{{ countTotalEntries }}</td>
                                            <td>100%</td>
                                        </tr>
                                        <tr>
                                            <td>Completed</td>
                                            <td>{{ countCompleteEntries }}</td>
                                            <td>{{ completePercentageRounded }}%</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table> -->
                        </v-card-text>

<!--
                    <p class="text-overline mb-0 mt-8">Form ID</p>
                    <p class="mb-0 pb-0">
                        {{ form.id }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Type</p>
                    <p class="mb-0 pb-0">
                        {{ typeDisplay }}
                    </p> -->

                    <!-- TODO: pagination, search, link to create a custom reports where user can change order of fields in table, format, etc. -->
                    <!-- <v-row no-gutters justify="end" class="px-5">
                        <v-checkbox label="Show incomplete entries" dense v-model="showIncompleteEntries"/>
                    </v-row> -->
                    <v-simple-table>
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left px-4" v-for="(input, inputIdx) in inputList" v-bind:key="inputIdx">
                                        {{ input.label }}
                                    </th>
                                    <!-- extra column for info tooltip -->
                                    <th class="px-1" style="width: 48px;"></th>
                                    <!-- extra column for action menu -->
                                    <th class="px-1" style="width: 48px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(entry, entryIdx) in entryList" v-bind:key="entryIdx" v-show="showIncompleteEntries || entry.status === 'done'">
                                    <td class="text-left px-4" v-for="(input, inputIdx) in inputList" v-bind:key="inputIdx">
                                        {{ entry.input[input.key] }}
                                    </td>
                                    <!-- info toolip -->
                                    <td class="text-right px-1">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width class="blue--text text--lighten-1" v-bind="attrs" v-on="on"/>
                                                <!-- <v-btn
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                Top
                                                </v-btn> -->
                                            </template>
                                            <span>Created {{ datetimeFromTimestamp(entry.created_on) }}<br/>Status: {{ statusText(entry.status) }}<br/>Time spent: {{ timeElapsed(entry.created_on, entry.status_on) }}</span>
                                        </v-tooltip>
                                    </td>
                                    <!-- action menu -->
                                    <td class="text-right px-1">
                                        <v-menu offset-y left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon color="purple" v-on="on">
                                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                </v-btn>
                                            </template>
                                            <v-list class="ma-0 pa-0">
                                                <v-list-item-group>
                                                <v-list-item @click="deleteEntry(entry, entryIdx)">
                                                    <!-- <v-list-item-content> -->
                                                        <v-list-item-title class="red--text">Delete</v-list-item-title>
                                                    <!-- </v-list-item-content> -->
                                                </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    </v-card>

                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Form Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.form, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Data <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.entryList, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-dialog v-model="editTimeFilterDialog" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title class="purple--text">Filter entries by date</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <!-- <v-card-text> -->
                                <p class="px-5">You can select entries by date range. The totals will also change to reflect entries in the selected date range.</p>
                            <!-- </v-card-text> -->
                            <v-form @submit.prevent="applyFilterByDate" class="px-5">
                                <v-checkbox v-model="filterByDateEnabled" label="Filter entries by date" dense/>
                                <template v-if="filterByDateEnabled">

                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-menu
                                            v-model="filterByDateFromMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                :value="dateFilterDisplayFrom"
                                                label="Start date"
                                                persistent-hint
                                                readonly
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <template #prepend-inner>
                                                        <font-awesome-icon :icon="['far', 'calendar-alt']" fixed-width class="purple--text text--darken-2"/>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="editDateFrom"
                                                no-title
                                                @input="filterByDateFromMenu = false"
                                            ></v-date-picker>
                                            </v-menu>
                                            <!-- <p>Date in ISO format: <strong>{{ editDateFrom }}</strong></p> -->
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-menu
                                            v-model="filterByDateToMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                            >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                :value="dateFilterDisplayTo"
                                                label="End date (inclusive)"
                                                persistent-hint
                                                readonly
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <template #prepend-inner>
                                                        <font-awesome-icon :icon="['far', 'calendar-alt']" fixed-width class="purple--text text--darken-2"/>
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="editDateTo"
                                                no-title
                                                @input="filterByDateToMenu = false"
                                            ></v-date-picker>
                                            </v-menu>
                                            <!-- <p>Date in ISO format: <strong>{{ editDateTo }}</strong></p> -->
                                        </v-col>
                                        </v-row>
                                </template>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="purple white--text" @click="applyFilterByDate" :disabled="filterByDateEnabled && (!editDateFrom || !editDateTo)">
                                    Apply
                                </v-btn>
                                <v-btn text class="grey--text" @click="editTimeFilterDialog = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}

@media print {
    .no-print {
        display: none !important;
    }
}
</style>

<script>
import { mapState } from 'vuex';
import { toMillis, toText } from '@libertyio/time-util-js';

function round(value, nearest) {
    return Math.round(value / nearest) * nearest;
}

export default {
    components: {
        // FormList,
    },
    data: () => ({
        error: null,
        account: null,
        form: null,
        invite: null,
        entryList: [],
        inputList: [],
        isAutorefreshEnabled: false,
        autorefreshTimer: null,
        showIncompleteEntries: false,

        // filter by date
        editTimeFilterDialog: false,
        filterByDateFromMenu: null,
        filterByDateToMenu: null,
        editDateFrom: null, // start date selected by user, INCLUSIVE; 'from' timestamp will be midnight start of day
        editDateTo: null, // end date selected by user, INCLUSIVE; 'to' timestamp will be midnight end of day
        filterByDateEnabled: false,
        filterByDateFrom: null, // timestamp, millis, inclusive
        filterByDateTo: null, // timestamp, millis, exclusive
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        // isViewReady() {
        //     return this.account !== null && this.form !== null;
        // },
        typeDisplay() {
            switch (this.form?.type) {
            case 'f':
                return 'Form';
            case 'p':
                return 'Poll';
            case 's':
                return 'Survey';
            default:
                return `Unknown[${JSON.stringify(this.form?.type)}]`;
            }
        },
        isPublished() {
            return this.form?.invite_id;
        },
        statusDisplay() {
            if (this.isPublished) {
                return 'Published';
            }
            return 'Draft';
        },
        refreshButtonColor() {
            return this.isAutorefreshEnabled ? 'amber lighten-2' : 'white';
        },
        countTotalEntries() {
            return this.entryList?.length;
        },
        countCompleteEntries() {
            return this.entryList?.filter((item) => item.status === 'done').length;
        },
        completePercentage() {
            const total = this.countTotalEntries;
            const complete = this.countCompleteEntries;
            if (Number.isInteger(total) && Number.isInteger(complete) && total > 0) {
                return (complete * 100) / total;
            }
            return undefined;
        },
        completePercentageRounded() {
            const percentage = this.completePercentage;
            const decimalPoints = 2;
            const slide = 10 ** decimalPoints;
            if (Number.isFinite(percentage)) {
                return Math.round(percentage * slide) / slide;
            }
            return undefined;
        },
        dateFilterDisplayFrom() {
            if (this.editDateFrom) {
                // const tzOffset = (new Date()).getTimezoneOffset() * 60000;
                // const d = new Date(this.editDateFrom);
                // const text = (new Date(Date.now() - tzOffset)).toISOString().substr(0, 10);
                // console.log(`dateFilterDisplayFrom: editDateFrom ${this.editDateFrom} => ${d.toString()} => ${text}`);
                return this.editDateFrom;
            }
            return null;
        },
        dateFilterDisplayTo() {
            if (this.editDateTo) {
                return this.editDateTo;
            }
            return null;
        },
        dateFilterDisplay() {
            if (this.filterByDateEnabled && this.editDateFrom && this.editDateTo) {
                return `${this.dateFilterDisplayFrom} - ${this.dateFilterDisplayTo}`;
            }
            return 'All time';
        },
    },
    watch: {
        isAutorefreshEnabled(newValue) {
            if (newValue && !this.autorefreshTimer) {
                this.autorefreshTimer = setInterval(() => this.loadFormData(), toMillis({ seconds: 5 }));
                console.log(`isAutorefreshEnabled: ${newValue}, set interval timer ${this.autorefreshTimer}`);
            }
            if (!newValue && this.autorefreshTimer) {
                console.log(`isAutorefreshEnabled: ${newValue}, clearing timer ${this.autorefreshTimer}`);
                clearInterval(this.autorefreshTimer);
                this.autorefreshTimer = false;
            }
        },
        editTimeFilterDialog(newValue, oldValue) {
            if (!newValue && oldValue) {
                // when closing the dialog, if the user checked the box but then didn't select any dates, uncheck the box for them
                if (this.filterByDateEnabled && (!this.editDateFrom || !this.editDateTo)) {
                    this.filterByDateEnabled = false;
                }
            }
        },
    },
    methods: {
        datetimeFromTimestamp(ts) {
            const d = new Date(ts);
            return d.toString();
        },
        statusText(status) {
            switch (status) {
            case 'new':
                return 'New';
            case 'done':
                return 'Done';
            default:
                return status;
            }
        },
        timeElapsed(from, to) {
            if (Number.isInteger(from) && Number.isInteger(to) && from > 0 && to > 0 && to > from) {
                // round to nearest second (1000 ms); we don't need to show milliseconds here
                const elapsed = round(to, 1000) - round(from, 1000);
                return toText(elapsed);
            }
            return 'N/A';
        },
        // monthName(month) {
        // },
        formatDate(date) {
            if (!date) return null;
            return date;
            // const [year, month, day] = date.split('-');
            // return `${day} ${monthName(month)} /${year}`;
        },
        parseDate(date) {
            if (!date) return null;
            const [month, day, year] = date.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadForm() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadForm: true });
                const response = await this.$client.account(this.$route.params.accountId).form.get(this.$route.params.formId);
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.form = response;
                    this.form.input ??= {};
                    this.form.sequence ??= [];
                    this.inputList = this.form.sequence.filter((item) => item.type === 'input').map((item) => ({ ...this.form.input[item.key], key: item.key }));
                    // if (this.form.website_id) {
                    //     this.loadWebsite();
                    // }
                    this.loadFormData();
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load form');
                }
            } catch (err) {
                console.error('failed to load form', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadForm: false });
            }
        },
        async loadFormData() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadFormData: true });
                const match = { form_id: this.$route.params.formId };
                if (this.filterByDateEnabled && Number.isInteger(this.filterByDateFrom) && Number.isInteger(this.filterByDateTo)) {
                    match.not_before = this.filterByDateFrom; // inclusive, timestamp in millis
                    match.before = this.filterByDateTo; // exclusive, timestamp in millis
                }
                const response = await this.$client.account(this.$route.params.accountId).formEntry.search(match);
                console.log(`loadFormData: response ${JSON.stringify(response)}`);
                if (response?.list) {
                    this.entryList = response.list;
                } else {
                    console.error('failed to load form data');
                    this.entryList = [];
                }
            } catch (err) {
                console.error('failed to load form data', err);
                this.entryList = [];
            } finally {
                this.$store.commit('loading', { loadFormData: false });
            }
        },
        async deleteEntry(entry, idx) {
            try {
                this.error = false;
                this.$store.commit('loading', { deleteEntry: true });
                const response = await this.$client.account(this.$route.params.accountId).formEntry.delete({ form_id: entry.form_id, entry_id: entry.entry_id });
                console.log(`deleteEntry: response ${JSON.stringify(response)}`);
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted' });
                    this.entryList.splice(idx, 1);
                } else {
                    console.error('failed to delete form entry');
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete entry' });
                }
            } catch (err) {
                console.error('failed to delete form entry', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete entry' });
            } finally {
                this.$store.commit('loading', { deleteEntry: false });
            }
        },
        async applyFilterByDate() {
            // Array.isArray(this.editDateRange) && this.editDateRange.length === 2 && typeof this.editDateRange[0] === 'string' && typeof this.editDateRange[1] === 'string'
            if (this.filterByDateEnabled && this.editDateFrom && this.editDateTo) {
                // date input is without timezone, but when we convert to timestamp javascript assumes it's UTC; the timestamp does need to be UTC but the user is entering local time so we need to adjust it so that the UTC timestamp correctly reflects midnight local time (instead of midnight UTC)
                const tzOffset = (new Date()).getTimezoneOffset() * 60000;

                // this.editDateRange like ['2022-04-21','2022-04-28']
                const first = Date.parse(this.editDateFrom) + tzOffset; // e.g. '2022-04-21' midnight start of day local time
                const second = Date.parse(this.editDateTo) + tzOffset + toMillis({ days: 1 }); // e.g. '2022-04-28' midnight end of day local time (midnight start of next day exclusive so the search will include all this selected day); NOTE that this isn't exactly correct for days that have leap seconds etc.; TODO: instead of adding this number of seconds, convert to a date, add one day, then convert back to milliseconds using a calendar library that will do the appropriate calendar math

                // const d = new Date(this.editDateFrom);
                // const text = (new Date(Date.now() - tzOffset)).toISOString().substr(0, 10);
                // console.log(`dateFilterDisplayFrom: editDateFrom ${this.editDateFrom} => ${d.toString()} => ${text}`);
                // return this.editDateFrom;

                // reject invalid range where 'from' is after 'to'
                if (first > second) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Start date must be same or earlier than end date' });
                    return;
                }

                console.log(`apply filter: from: [${first}] ${new Date(first).toString()} to [${second}] ${new Date(second).toString()}`);
                this.filterByDateFrom = first;
                this.filterByDateTo = second;

                // because of the way the date picker works, it's too easy to select dates in the "wrong" order; so just switch it around if needed
                // if (second > first) {
                //     // normal order
                //     this.filterByDateFrom = first;
                //     this.filterByDateTo = second;
                // } else {
                //     // reverse order
                //     this.filterByDateFrom = second;
                //     this.filterByDateTo = first;
                // }

                this.editTimeFilterDialog = false;
                await this.loadFormData();
            } else {
                this.editDateFrom = null;
                this.editDateTo = null;
                this.filterByDateFrom = null;
                this.filterByDateTo = null;
                this.editTimeFilterDialog = false;
                await this.loadFormData();
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadForm();
    },
};
</script>
